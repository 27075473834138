import Link from 'next/link'
import React, { useState } from 'react'
import { signIn, signOut, useSession } from 'next-auth/react'
import logo from '../../public/WhiteLogo.svg'
import Image from 'next/image'
import Router from 'next/router'
import Profile from './Icons/Profile'
import { useQuery } from 'react-query'
import closeIcon from '../../public/close_icon.svg';

import { getSummary } from '../api/tournaments'

const NavLinks = () => {
  const { data: session, status }: any = useSession()
  const accessToken = session?.data?.accessToken
  const [displayMobile, setDisplayMobile] = useState(false)
  const {
    data: homeData
  } = useQuery<any, Error, any>(
    'homeSummary',
    async () => {
      return await getSummary(accessToken)
    },
    {
      enabled: !!accessToken
    }
  )

  const GlobalPool = () => {
    // console.log("Home data is ", homeData);
    if (!homeData) {
      return null
    }
    const globalPoolId: string = homeData?.CurrentTournament?.globalPoolId
    return homeData.Status === 'CLOSED'
      ? null
      : (
        <div className={linkStyle}>
          <Link href={`/pool/${globalPoolId}`}>
            <a onClick={() => { setDisplayMobile(false) }}>Leaderboard</a>
          </Link>
        </div>
      )
    // else return null;
  }

  const Home = () => (
    <div className={linkStyle}>
      <Link href="/landing">
        <a onClick={() => { setDisplayMobile(false) }}>Home</a>
      </Link>
    </div>
  )

  const MyProfile = () => (
    <div className={linkStyle}>
      <Link href="/user/profile">
        <a onClick={() => { setDisplayMobile(false) }}>My Profile</a>
      </Link>
    </div>
  )

  const Pools = () => {
    return (
      <div className={linkStyle}>
        <Link href="/pools">
          <a onClick={() => { setDisplayMobile(false) }}>Pools</a>
        </Link>
      </div>
    )
  }

  const linkStyle =
    'p-2 hover:text-gray-300 text-sm whitespace-nowrap text-[18px] font-bold uppercase'
  const mobileDetails = (
    <div className="absolute width-100 text-2xl top-0 right-0 bg-white h-200 pt-[30px] px-[25px]">
      <div className='flex justify-end'>
        <button type='button' title='menu' onClick={e => {
          e.preventDefault()
          setDisplayMobile(!displayMobile)
        }}>
          <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 13">
              <path id="Line 2" d="M2 2L17 17" stroke="black" stroke-width="2.05883" stroke-linecap="round" />
              <path id="Line 3" d="M17 2L2 17" stroke="black" stroke-width="2.05883" stroke-linecap="round" />
            </g>
          </svg>
        </button>
      </div>


      <Home />
      <GlobalPool />
      {status === 'authenticated' && <MyProfile />}
      {status !== 'authenticated' && (
        <div className={linkStyle}>
          <a
            href={'/api/auth/signin'}
            onClick={(e) => {
              e.preventDefault()
              signIn('fusionauth', { callbackUrl: '/landing' }).finally(() => { })
            }}
          >
            Log in
          </a>
        </div>
      )}
      <Pools />
      <div className={linkStyle}>
        <Link href="/faq">
          <a onClick={() => { setDisplayMobile(false) }}>How It works</a>
        </Link>
      </div>
      <div className={linkStyle}>
        <Link href="/contact">
          <a onClick={() => { setDisplayMobile(false) }}>Contact Us</a>
        </Link>
      </div>
      {status === 'authenticated' && (
        <div className={linkStyle}>
          <a
            href={'/api/auth/signin'}
            onClick={(e) => {
              e.preventDefault()
              signOut({
                callbackUrl: '/',
                redirect: true
              })
            }}
          >
            Log out
          </a>
        </div>
      )}
    </div>
  )

  const mobileMenu = (
    <div
      className="flex grow sm:hidden items-center justify-end cursor-pointer"
      onClick={(e) => {
        e.preventDefault()
        setDisplayMobile(!displayMobile)
      }}
    >
      <div className="space-y-2">
        <div className="w-8 h-0.5 bg-black"></div>
        <div className="w-8 h-0.5 bg-black"></div>
        <div className="w-8 h-0.5 bg-black"></div>
      </div>
    </div>
  )

  const wideMenu = (
    <div className="font-['Montserrat'] font-bold hidden sm:flex flex-row justify-items-center items-start grow text-black text-sm mt-2 sm:p-2">
      <Home />
      <GlobalPool />
      {session && <MyProfile />}
      {session && <Pools />}
      <div className={linkStyle}>
        <Link href="/faq">How it Works</Link>
      </div>
      <div className={linkStyle}>
        <Link href="/contact">Contact Us</Link>
      </div>
    </div>
  )

  return (
    <React.Fragment>
      {mobileMenu}
      {wideMenu}
      {displayMobile ? mobileDetails : null}
    </React.Fragment>
  )
}

const SignInElement = () => {
  const { data: session }: any = useSession()
  const userName = session ? `${session.firstName} ${session.lastName}` : null
  const showManageIfAdmin = () => {
    if (session?.isAdmin) {
      return (
        <Link href="/manage/tournaments/manage">
          <span className="text-sm ml-2 hover:cursor-pointer font-bold">
            <a>Manage</a>
          </span>
        </Link>
      )
    }
  }

  return (
    <div className="text-right">
      {!(session) && (
        <>
          <span className="font-serif">
            <small>You are not logged in</small>
            <br />
          </span>
          <a
            href={'/api/auth/signin'}
            onClick={(e) => {
              e.preventDefault()
              void signIn('fusionauth', { callbackUrl: '/landing' })
            }}
          >
            Log in
          </a>
        </>
      )}
      {session && (
        <>
          <div className="flex grow-0 flex-nowrap flex-col align-items-center">
            <div className="flex items-center">
              <Profile />
              <span className="pl-1 font-serif text-sm font-bold whitespace-nowrap">
                {userName ?? session.email}
                <br />
              </span>
            </div>
            {showManageIfAdmin()}
          </div>
          <br />
          <button
            onClick={(e) => {
              e.preventDefault()
              signOut({
                callbackUrl: 'https://auth.golfchamps.net/oauth2/logout',
                redirect: true
              })
            }}
            className='text-[13px] font-semibold'
          >
            Sign Out
          </button>
        </>
      )}
    </div>
  )
}

export const Header = (props) => {
  const { data: session }: any = useSession()
  return (
    <div className="sticky top-0 z-50 w-full flex bg-white text-black">
      <div className="flex flex-row grow items-center">
        <div className="flex pt-2 pl-2 hover:cursor-pointer">
          <Link href={session ? '/landing' : '/'}>
            <div className="flex flex-row flex-nowrap items-center">
              <div className="w-12">
                <Image alt="Logo" src={logo} />
              </div>
              <span className="pl-2 pr-2 bold text-sm uppercase font-['Montserrat'] whitespace-nowrap font-extrabold">
                Golf Champs
              </span>
            </div>
          </Link>
        </div>
        <div className="flex grow p-1">
          <NavLinks />
        </div>
        <div className="p-2 hidden sm:flex">
          <SignInElement />
        </div>
      </div>
    </div>
  )
}
