// export const API_URL = "https://api.golfchamps.net";
// export const AUTH_URL = "https://auth.golfchamps.net";

export const API_URL = process.env.NEXT_PUBLIC_API_URL

//NOTE - we are not using a localhost Fusion instance - we're using the same instance hosted on ECS
export const AUTH_URL = process.env.NEXT_PUBLIC_AUTH_URL

export const URL =
  process.env.NEXT_PUBLIC_VERCEL_ENV == "local"
    ? "http://localhost:3000"
    : process.env.NEXT_PUBLIC_URL;
