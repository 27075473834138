import { API_URL } from "../lib/constants";
import fetchWithRequestId from "./common";

export const getSummary = (accessToken) => {
  return fetchWithRequestId(`${API_URL}/tournaments/home`, {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

export const getTournaments = (accessToken) => {
  return fetchWithRequestId(`${API_URL}/tournaments`, {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

export const getUpcomingTournaments = (accessToken) => {
  return fetchWithRequestId(`${API_URL}/tournaments?type=upcoming`, {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

export const getTournament = (accessToken, tournamentId) => {
  return fetchWithRequestId(`${API_URL}/tournaments/${tournamentId}`, {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

export const getScoreboardByTournamentId = (tournamentId) => {
  return fetchWithRequestId(
    `${API_URL}/tournaments/${tournamentId}/scoreboard`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

export const createTournament = (accessToken, data) => {
  return fetch(`${API_URL}/tournaments`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (!res.ok && res.status == 500) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const updateTournament = (accessToken, data) => {
  return fetch(`${API_URL}/tournaments/${data.id}`, {
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (!res.ok && res.status == 500) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const addPlayerExternalIdToTournament = (accessToken, tournamentId, playerExternalId) => {
  return fetch(`${API_URL}/players/${tournamentId}/addExternalPlayer?externalId=${playerExternalId}`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (!res.ok && res.status == 500) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const addPlayerToTournament = (accessToken, tournamentId, playerId) => {
  return fetch(`${API_URL}/players/${tournamentId}/addPlayer/${playerId}`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (!res.ok && res.status == 500) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const removePlayerFromTournament = (
  accessToken,
  tournamentId,
  playerId
) => {
  return fetch(`${API_URL}/players/${tournamentId}/addPlayer/${playerId}`, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (!res.ok && res.status == 500) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const updatePlayerScores = (accessToken, data) => {
  return fetch(`${API_URL}/tournaments/${data.id}/overwritescores`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (!res.ok && res.status == 500) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .catch((error) => {
      throw new Error(error);
    });
};
